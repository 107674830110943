// Modules
import axios from "axios";
import { useEffect, useState } from "react";

// Environment variables
import { environment } from "../../../../environment";

// Components
import { DishResize } from "../room/resize/dish.layout";

// Properties
import { SelectorDevicesProperties } from "./selectorDevices.properties";
import { SelectorDeviceType } from "./selectorDevices.types";

// Styles
import "./selector.styles.scss";
import { useTranslation } from "react-i18next";

/**
 * Selector Devices Component
 * @description Component to select devices
 * @param {SelectorDevicesProperties} properties
 * @returns {JSX.Element}
 */
export function SelectorDevicesComponent(
  properties: SelectorDevicesProperties
) {
  // translation function
  const { t } = useTranslation();

  // state to avoid multiple clicks in media selector
  const [selected, setSelected] = useState(false);

  // array of devices
  const [cameras, setCameras] = useState<SelectorDeviceType[]>([]);

  // get cameras from backend
  const getCameras = async () => {
    const groups = properties.groupsData;
    if (Array.isArray(groups) && groups.length > 0) {
      const allDevicesSet = new Map<string, SelectorDeviceType>();

      for (const group of groups) {
        for (const groups_devices of group.groups_devices) {
          allDevicesSet.set(groups_devices.device.id, groups_devices.device);
        }
      }

      // list of devices
      const camerasList = Array.from(allDevicesSet.values());

      // check is validateIsActive
      const camerasActives: any = [];
      for (const cameraSelect of camerasList) {
        // check if device is in properties.localScreams
        const active = await validateIsActive(cameraSelect);

        // device used state
        let used = false;

        if (cameraSelect?.filter?.label) {
          // search in local streams
          const localStream = properties.localScreams.find((stream) => {
            return stream.getTracks().find((track) => {
              return track.label.includes(cameraSelect?.filter?.label);
            });
          });

          // if local stream exist, device is used
          if (localStream) used = true;
        }

        // add camera to list
        active &&
          camerasActives.push({
            ...cameraSelect,
            used: used,
          });
      }

      // set cameras
      setCameras(camerasActives);
      properties.setHasDevicesBeenFetched(true);
    }
  };

  // get cameras on load
  useEffect(() => {
    getCameras();
  }, [properties.groupsData]);

  // validate if device is active
  const validateIsActive = async (camera: any) => {
    if ("label" in camera.filter) {
      // get all enumerate devices
      const devices = await navigator.mediaDevices.enumerateDevices();

      // search device with label
      const device = devices.find((device) => {
        return "label" in camera.filter
          ? device.label.includes(camera.filter.label)
          : false;
      });

      // if device exist, return true
      if (device) return device;
    } else {
      // get stream
      const stream = await properties.deviceInstance.getFilterStream(
        camera.filter
      );
      if (stream) return stream;
    }
    return undefined;
  };

  return (
    <>
      {properties.canBeDisplayed &&
        cameras.map((camera) => {
          return (
            <div
              className="camera"
              style={{
                backgroundColor: camera.used ? "#b7bffc" : "#fff",
                borderStyle: "solid",
                borderColor: camera.used ? "#2f49ff" : "#fff",
              }}
              key={camera.id}
              onClick={async () => {
                // avoid multiple clicks
                if (selected) return;

                // set selected state
                setSelected(true);

                if ("label" in camera.filter) {
                  // search again in enumerate devices
                  const devices =
                    await navigator.mediaDevices.enumerateDevices();

                  // label contain camera.filter.label
                  const device = devices.find((device) => {
                    return "label" in camera.filter
                      ? device.label.includes(camera.filter.label)
                      : false;
                  });

                  if (device) {
                    // Check if device is being used
                    const used = properties.localScreams.find((stream) => {
                      return stream.getTracks().find((track) => {
                        return track.label.includes(device.label);
                      });
                    });

                    if (used) {
                      properties.removeStream?.(
                        properties.localScreams.indexOf(used)
                      );
                      setSelected(false);
                      properties.setScene?.(undefined);
                      return;
                    }

                    // get stream
                    const stream =
                      await properties.deviceInstance.getFilterStream({
                        video: camera.filter.video
                          ? { deviceId: device.deviceId }
                          : false,
                        audio: camera.filter.audio
                          ? { deviceId: device.deviceId }
                          : false,
                      });

                    const manualId = camera.manual_id ?? "";

                    // add stream
                    if (stream)
                      properties.addStream?.(
                        stream,
                        { type: "med.device" },
                        { manual_id: manualId }
                      );
                  }
                } else {
                  // get stream from filter
                  const stream =
                    await properties.deviceInstance.getFilterStream(
                      camera.filter
                    );
                  if (stream)
                    properties.addStream?.(
                      stream,
                      { type: "med.device" },
                      { manual_id: camera.manual_id ?? "" }
                    );
                }

                // resize dish cameras
                new DishResize(
                  "DishScenary",
                  "DishScenary",
                  "DishVideoComponent"
                ).resize();

                // close selector
                properties.setScene?.(undefined);
              }}
            >
              {
                // hide add icon if device is used
                <i className={camera.used ? "las la-times" : "las la-plus"}></i>
              }
              <div className="name">{camera.name}</div>
              <div
                className="image"
                style={{
                  backgroundColor: camera.used ? "#b7bffc" : "#fff",
                  backgroundImage: camera.icon ? `url(${camera.icon})` : "none",
                }}
              />
            </div>
          );
        })}
      {cameras.length === 0 && (
        <div
          className="no-camera"
          style={{
            color: "#fff",
            flex: 1,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("selector.empty")}
        </div>
      )}
    </>
  );
}
