import { useState, useEffect, useMemo } from 'react';

/**
 * Custom hook to handle the user idle time. It receives the clientIdleTime, roomId and isUserAlone.
 *
 * @param clientIdleTime - The maximum idle time that the user can be inactive before being considered idle.
 * @param roomId - The room id where the user is. If it is not defined, the user will be considered idle.
 * @param isUserAlone  - If the user is alone in the room. If it is not defined or false, the user will be considered idle.
 *
 * @returns isUserIdle - A boolean that indicates if the user is idle or not.
 */
const useIdleTimer = (clientIdleTime: string, roomId?: string, isUserAlone?: boolean) => {
  // State to store the idle time in milliseconds
  const [idleTime, setIdleTime] = useState<number>(0);

  // State to store if the user is idle
  const [isUserIdle, setIsUserIdle] = useState<boolean>(false);

  /**
   * Returns the maximum idle time in milliseconds based on the clientIdleTime
   * (format: 1s, 1m, 1h or 1d). By default, it returns 365 days in milliseconds (31536000000).
   */
  const getMaxIdleTime = useMemo(() => {
    // Match the clientIdleTime with the regex to get the number and the unit
    const match = clientIdleTime.match(/(\d+)([smhd])/);

    // Return the maximum idle time based on the unit and the number provided
    switch (match?.[2]) {
      case 's':
        return parseInt(match[1]) * 1000;
      case 'm':
        return parseInt(match[1]) * 60 * 1000;
      case 'h':
        return parseInt(match[1]) * 60 * 60 * 1000;
      case 'd':
        return parseInt(match[1]) * 24 * 60 * 60 * 1000;
      default:
        return 365 * 24 * 60 * 60 * 1000;
    }
  }, [clientIdleTime]);

  // Effect to handle the user idle time
  useEffect(() => {
    // Function to handle the user activity
    const handleActivity = () => {
      setIdleTime(0);
      setIsUserIdle(false);
    };

    // Set an interval to check the idle time
    const interval = setInterval(() => {
      // If the user is not alone, reset the idle time and return
      if (!isUserAlone) {
        setIdleTime(0);
        setIsUserIdle(false);
        return;
      }

      /* If the user is alone or the room is not defined, check if the idle time is greater than the maximum
      idle time. If it is, set the user as idle and clear the interval. */
      setIdleTime((prevIdleTime) => {
        if (
          (!roomId && (prevIdleTime >= getMaxIdleTime)) ||
          ((roomId && isUserAlone && prevIdleTime >= getMaxIdleTime))
        ) {

          setIsUserIdle(true);
          clearInterval(interval);

          localStorage.removeItem('user');
          localStorage.removeItem('user_settings');

          window.removeEventListener('load', handleActivity);
          window.removeEventListener('mousemove', handleActivity);
          window.removeEventListener('keydown', handleActivity);
          window.removeEventListener('scroll', handleActivity);
        }
        return prevIdleTime + 1000;
      });
    }, 1000);

    // Add event listeners to handle user activity
    window.addEventListener('load', handleActivity);
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Clear the interval and remove the event listeners when the component is unmounted
    return () => {
      clearInterval(interval);
      window.removeEventListener('load', handleActivity);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [getMaxIdleTime, roomId, isUserAlone]);

  return isUserIdle;
};

export default useIdleTimer;
